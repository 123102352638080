import React from 'react'

const Error = () => {
  return (
    <div className='mbody'>
      <p>Error 404: Path does not exist!</p>
    </div>
  )
}

export default Error
