export const stat4_results_request = 'stat4_results_request'
export const stat4_results_success = 'stat4_results_success'
export const stat4_results_failure = 'stat4_results_failure'

export const stat5_results_request = 'stat5_results_request'
export const stat5_results_success = 'stat5_results_success'
export const stat5_results_failure = 'stat5_results_failure'

export const stat6_results_request = 'stat6_results_request'
export const stat6_results_success = 'stat6_results_success'
export const stat6_results_failure = 'stat6_results_failure'
